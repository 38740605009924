// -----------------------------------//
// GLOBAL STYLES
// -----------------------------------//

// BODY
body                {color: #444; font-size: 13px; font-family: sans-serif; text-align: center; line-height: 1.528571429;padding: 0px; margin: 0; background: #fff; }

// LINKS
body a              {color: #999; text-decoration: none; transition: (all 0.3s ease-in-out);}
body a:hover, body a:focus {text-decoration: underline;}

// LINKED IMAGES
a img, img a, img, p a img 	{border: 0; border-bottom: 0; text-decoration: none; vertical-align: middle; }
a img:hover					{opacity: 0.8}

// HEADINGS
h1,h2,h3,h4,h5,h6	{font-weight: 500;}
h1					{font-size: 48px; margin: 0 0 20px 0; color: #000; line-height:1.2; }
h2					{font-size: 20px; margin: 25px 0 20px 0; color: #000; line-height:1.3; }
h3					{font-size: 15px; margin: 25px 0 10px 0; padding: 5px 10px; background: #333;color: #fff;}
h4					{@extend h3; background-position: 10px center; background-repeat: no-repeat; padding-left: 30px; }
h4.open 			{background-image: url(/i/design/expanding-faq/minus.png); }
h4.closed 			{background-image: url(/i/design/expanding-faq/plus.png); }
h5 					{font-size: 12px; }
h6, small 			{font-size: 10px; }
h1+h2				{margin-top:0; border:0; padding:0; }
h1+p, h2+p, h3+p	{margin-top:0; }

// PARAGRAPHS AND LIST ITEMS
p					{margin: 12px 0; padding: 0; }
li					{margin: 2px 0; padding: 0 0 0 23px; background-image: url(/i/icons/bullet_black.png); background-repeat: no-repeat; }

// FORMS
form 					{margin:0; padding:0; @extend .clearfix; }
fieldset 				{border: 1px solid #c0c0c0; margin: 0 2px; padding: 0.35em 0.625em 0.75em; }
legend 					{border: 0; padding: 0; white-space: normal; *margin-left: -7px; }
button, html input[type="button"], input[type="reset"], input[type="submit"] {-webkit-appearance: button; cursor: pointer; *overflow: visible; /* 4 */ }
button[disabled], html input[disabled] {cursor: default; }
input[type="checkbox"], input[type="radio"] {box-sizing: border-box; padding: 0; *height: 13px; *width: 13px; }
input[type="search"] 	{-webkit-appearance: textfield; box-sizing: content-box; }
input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration { -webkit-appearance: none; }
button::-moz-focus-inner, input::-moz-focus-inner {border: 0; padding: 0; }
textarea 				{resize: vertical; overflow: auto; vertical-align: top; }
label 					{float: left; margin: 10px 0; width: 90px; display: inline;}
form .formfield 	    {margin: 10px; float: left; display: inline; width: 200px; border: 1px solid #CCC; padding: 2px; }
form div			    {margin: 15px 0; display: block; clear: left; vertical-align: top; }
form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] {border: 1px solid #CCC; padding: 4px; width: 300px; margin: 0; background-color: #EFEFEF;}
input[type="text"]:focus, form div textarea:focus, form span textarea:focus, form div input[type="textarea"]:focus, form div input[type="password"]:focus {border-color: #000; }
form div span.label label, form div span.label,span.spacer,span.multiple span {width: 180px; float: left; margin: 0 0 0 0; } 
form div span.multiple  {float: left; } 
form div span.button    {padding-left: 180px; background: transparent; border: 0; } 
form div span.button input	{background: #666; color:#FFF; margin: 0; padding: 8px 10px; font-weight:bold; border: 0; }
form div span.button input:hover {background: #888; }
form div.clear 			{clear: both; padding-top: 5px; } 

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper            {margin: 0 auto; text-align: center; }
.wrapper 			{width: 990px; margin: 0 auto; @extend .clearfix; }

// -----------------------------------//
// HEADER
// -----------------------------------//

header              { }
header > .wrapper   {padding: 0; position: relative; z-index: 60; }

header h1 	        {margin: 0; font-size: 48px;display: inline-block;padding: 24px 0;}
header h1 a       	{color: #000;}
header h1 a:hover 	{color: #333;}

#logobar{text-align: left;}
img#logo{float: left;}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

#FS					{margin: 35px 0 0 0; padding: 0;@extend .clearfix; float: right;position: relative;}
#FS #search-field	{width: 150px; height: 30px;background: #fff;vertical-align: top;padding: 0 20px 0 10px;box-shadow:inset 0 0 0 1px #000}
#FS #search-submit	{position: absolute;right: 0;top: 0;padding: 5px;}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//
$search-highlight-background-color: lightblue;
$search-highlight-color: darkblue;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box{display: inline-block; background: #f1f1f1; padding: 5px 15px; font-size: 16px; border-radius: 4px; border: 1px solid #ccc; text-transform: uppercase;}
.search-numbers{text-transform: uppercase; font-weight: bold; font-size: 12px;
	span{background: #f1f1f1; padding: 3px 8px; margin: 0 3px;border-radius: 2px; border: 1px solid #ccc; }
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET 
// -----------------------------------//

#google_translate_element   {width: 110px;height: 30px;background: #fff;float: right;position: relative;margin: 35px 2px 0 0;box-shadow:inset 0 0 0 1px #000;
	//Translate text
	&:before{content: "Translate";position: absolute;left: 10px; top: 5px;color: #000;overflow: hidden;}
	//Translate arrow
	&:after{content: "";width: 0; height: 0; border-style: solid; border-width: 5px 5px 0 5px; border-color: #000 transparent transparent transparent;position: absolute;top: 13px; right: 8px;}
	
	//Select size
	.goog-te-gadget .goog-te-combo{margin: 0;width: 100%;height: 30px;}
}

.goog-te-gadget, .goog-te-combo{opacity: 0;}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder         {width: 990px; margin: 0px auto; padding: 30px 0 30px 0; background: #FFF; clear: both; text-align: left; @extend .clearfix; }

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column     {width: 990px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left; }

//Content 2
.Content2Column     {width: 600px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left; }

//Content 3
.Content3Column     {width: 430px; margin: 0; padding: 0; float: left; min-height:350px; text-align:left; }

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft			{width: 310px; margin: 0; padding: 0 0 0 0; min-height:250px; text-align:left; float: left; }
.ColumnRight		{width: 310px; margin: 0; padding: 0 0 0 0; min-height:250px; text-align:left; float: right; }

.ColumnLeft ul, .ColumnRight ul {margin: 0; padding: 0; list-style: none; }
.ColumnLeft nav ul a:link, .ColumnRight nav ul a:visited, .ColumnRight nav ul a:link, .ColumnRight nav ul a:visited {display: block; }
.ColumnLeft li 		        	{border-bottom: 1px solid #EEE; } // Hack to fix IE/Win's broken rendering of block-level anchors in lists
html > body .ColumnLeft li    	{border-bottom: none; } // Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading            	  { margin: 30px 0 0 0; padding: 0; box-shadow:inset 0 0 0 1px #000;text-align: center;}
.SideHeading:first-child 	  { margin-top: 0; }

// Heading
.SideHeading h2         	  { display: block;text-align: center;font-size: 24px;padding: 20px 0;margin: 0;}

// List
ul.SideList             	  { margin:0 0 0 0; padding: 0; border: 0; list-style: none; text-align: left;}
ul.SideList li         		  { margin:0 0 0 0; padding: 0; background: none;}

ul.SideList li a       		  { margin: 0; padding: 5px 10px; color: #333; font-size: 0.89em; font-weight: normal;display: block; }
ul.SideList li a:hover  	  { background: #999; color: #FFF; text-decoration: none;}
ul.SideList li a.child        { background: url(/i/design/lite/bg_nav_li_a_child.png) 20px 12px no-repeat; padding-left: 40px;
	&:hover{background-color: #999;}
}


// Date and Title
ul.SideList li p        { margin: 0; padding: 0; }
ul.SideList p.date      { float: left; width: 55px;height: 55px;text-align: center;background: #000;color: #fff;text-transform: uppercase;font-size: 14px;line-height: 130%;
	span{display: block;font-weight: bold;font-size: 24px;margin: 10px 0 0 0;}
}
ul.SideList p.title     { float: right; width: 75%;font-size: 15px;}

// More links
.SideHeading a.more     {display: inline-block;font-size: 15px;padding: 15px 10px;}

//Diary
#SideHeadingDiary{}

//News
#SideHeadingNews{}

//Related pages
#SideHeadingPages{}


// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home{background: rgba(0, 0, 0, .4);position: fixed;top: 0;left: 0;width: 100%;height: 100%;z-index: 8000;
	.message-modal{position: absolute;top: calc(50% - 300px);left: calc(50% - 465px - 40px - 10px);width: 930px;height: auto; margin: 0 30px; padding: 20px;background: rgba(255, 255, 255, .9);
		.modal-content{background: rgba(255, 255, 255, .9);background-position: -120px 50px ;box-shadow:inset 0 0 0 1px #fff;display: inline-block;width: 100%;height: 100%;text-align: center;
			.modal-text{display: block; padding: 45px;
				p{margin: 0;}
			}
			a.close-modal{position: absolute;top: 31px;right: 31px;}
		}
	}
}

.hide-modal{display: none !important;}



// -----------------------------------//
// FOOTER
// -----------------------------------//

footer                { clear: both; }
footer > .wrapper     { margin: 0 auto; padding: 20px 0px; color: #000; text-align: left; }
footer > .wrapper p   { margin: 5px 0; display: inline-block;}
footer > .wrapper a   {  }

#credit        { margin: 0; padding: 0; float: right; text-align:right;}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: darkblue;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

$blockquote-background-color: #EFEFEF;
$blockquote-border-color: #EBEBEB;
$blockquote-color: #333;
$blockquote-speech-mark-color: #999;
$blockquote-font-size: 1em;

// -----------------------------------//
// CALENDAR TABLE BACKGROUND
// -----------------------------------//

.fc-content { background: none; }


// -----------------------------------//
// BROWSER DETECTION
// -----------------------------------//

#browserblast         { position: fixed; top: 0; left: 0; width: 100%; height: 40px; border-bottom: 1px solid #D4C790; font-family: sans-serif; font-size: 12px; font-weight: bold; line-height: 40px; color: #6d6647; text-align: center; background: #FAF4AF; }
#browserblast a       { color: green; text-decoration: underline; }
.unsupported-browser  { margin-top: 41px; }

// -----------------------------------//
// FAQ SCRIPT
// -----------------------------------//

// h3.faq-open { background: #EFEFEF url(/i/design/expanding-faq/minus.png) right 12px center no-repeat; }
// h3.faq-closed { background: #EFEFEF url(/i/design/expanding-faq/plus.png) right 12px center no-repeat; }

// -----------------------------------//
// SWITCH MOBILE
// -----------------------------------//

#switch-mobile      { width:100%; margin: 0; padding: 20px 0; background:#EFEFEF; border-top:1px solid #EBEBEB; border-bottom:1px solid #EBEBEB; text-align:center; color:#777; font-family: sans-serif; font-size: 12px; display:block; clear:both; }
#switch-mobile a    { text-decoration: underline; }