// -----------------------------------//
// Buttons
// -----------------------------------//
.turquoise-button{
	background: #1bbc9b;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.deep-green-button{
	background: #0f6d5b;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.emerald-button{
	background: #2dcc70;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.forest-green-button{
	background: #43af26;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.sky-blue-button{
	background: #33cefb;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.standard-blue-button{
	background: #0099ff;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.purple-rain-button{
	background: #9a59b5;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.violet-button{
	background: #7044ad;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.royal-blue-button{
	background: #175fa2;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.midnight-blue-button{
	background: #334960;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.sunflower-button{
	background: #f2c50a;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}


.carrot-button{
	background: #e67f22;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.red-brick-button{
	background: #e54e39;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.bubble-gum-button{
	background: #e79096;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.silver-button{
	background: #bec3c7;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}

.concrete-button{
	background: #95a5a5;
	color: #fff;
	padding: 15px 20px;
	border-radius: 4px;
	box-shadow:inset 0 -5px 0 0 rgba(0,0,0,.2);
	a{color: #fff}
}





// -----------------------------------//
// Featured boxes
// -----------------------------------//


// Full width box
.full-width-box{
	width: calc(100% - 40px);
	margin: 20px 0;
	padding: 20px;
	display: block;
	background: #e8e8e8;
	color: #333;
}

// Outline box
.outline-box{
	width: calc(100% - 48px);
	margin: 20px 4px;
	padding: 20px;
	display: block;
	background: #f1f1f1;
	color: #333;
	box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #dadada;
}

// Round border
.round-border-box{
	width: calc(100% - 40px);
	margin: 20px 0;
	padding: 20px;
	display: block;
	background: #bdbdbd;
	color: #333;
	border-radius: 5px;
}

// Drop shadow box
.drop-shadow-box{
	width: calc(100% - 40px);
	margin: 20px 0;
	padding: 20px;
	display: block;
	background: #fff;
	color: #333;
	box-shadow: 0 0 10px 0 #C3C5C9;
}

// News flash
.newsflash{
	color: #84c9d2;
	padding: 10px 10px 5px 10px;
	border: 5px solid #41a9b7;
	background: #84c9d2;
	background-repeat: no-repeat;
	background-position: right 0px;
	
		h2{
			color: #fff;
			margin: 0 0 8px 0;
			padding: 0 0 8px 0;
			font-size: 1.2em;
			border-bottom: 1px solid #a0e2ea;
			border-top: 0;
			border-right: 0;
			border-left: 0;
			font-weight: bold;
			text-transform: uppercase;
			
			img{
				float: left;
				margin: 0 10px 0 0;
			}
		}
	p{
		color: #fff;
		margin: 5px 0;
	}
	a{
		text-decoration:underline;
	}
}

// Info box
.infobox{
	color: #fff;
	padding: 10px 10px 5px 10px;
	border: 5px solid #4fad3d;
	background: #6ac259;
	background-repeat: no-repeat;
	background-position: right 0px;
	
	h2{
		color: #fff;
		margin: 0 0 8px 0;
		padding: 0 0 8px 0;
		font-size: 1.2em;
		border-bottom: 1px solid #b4e4aa;
		border-top: 0;
		border-right: 0;
		border-left: 0;
		font-weight: bold;
		text-transform: uppercase;
		
		img{
			float: left;
			margin: 0 10px 0 0;
		}
	}
	p{
		color: #fff;
		margin: 5px 0;
	}
	a{text-decoration:underline;}
}

// Alert box
.alertbox{
	color: #fff;
	padding: 10px 10px 5px 10px;
	border: 5px solid #ffa342;
	background: #FDBB75;
	background-repeat: no-repeat;
	background-position: right 0px;
	
	h2{
		color: #fff;
		margin: 0 0 8px 0;
		padding: 0 0 8px 0;
		font-size: 1.2em;
		border-bottom: 1px solid #ffdab2;
		border-top: 0;
		border-right: 0;
		border-left: 0;
		font-weight: bold;
		text-transform: uppercase;
		img{
			float: left;
			margin: 0 10px 0 0;
		}
	}
	p{
		color: #fff;
		margin: 5px 0;
	}
	a{text-decoration:underline;}
}

// Warning box
.warningbox {
	color: #fff;
	padding: 10px 10px 5px 10px;
	border: 5px solid #ff5f4d;
	background: #FD8375;
	background-repeat: no-repeat;
	background-position: right 0px;
	
	h2{
		color: #fff;
		margin: 0 0 8px 0;
		padding: 0 0 8px 0;
		font-size: 1.2em;
		border-bottom: 1px solid #ffbeb6;
		border-top: 0;
		border-right: 0;
		border-left: 0;
		font-weight: bold;
		text-transform: uppercase;
		
		img{
			float: left;
			margin: 0 10px 0 0;
		}
	}
	p{
		color: #fff;
		margin: 5px 0;
	}
	a{text-decoration:underline;}
}


// -----------------------------------//
// Featured Lists
// -----------------------------------//

// Round border list
ul.round-border-list{
	display: block;
	
	li{
		display: block;
		margin: 0 0 5px 0;
		padding: 10px 10px;
		background: #efefef;
		border-radius: 4px;
		font-size: 15px;
	}
}

// Quick links list
.quick-links-list{
	background: #f7f7f7;
	box-shadow: 0 0 0 1px rgba(0,0,0,0.08), 8px 8px 0 0 rgba(143,143,143,0.15);
	border-radius: 10px;
	overflow: hidden;
	padding: 10px;
	display: block;
	margin: 0 8px 0 0;
	
	h2{
		font-size: 25px;
		color: #444;
		margin: 10px 0 15px 0;
		border-bottom: 1px solid #bdbdbd;
		padding: 0 0 10px 0;
	}
	
	ul{
		display: block;
		box-shadow: inset 0 0 0 1px #e4e4e4;
		overflow: hidden;
		border-radius: 8px;
		
		li{
			display: block;
			box-shadow: inset 0 0 0 1px #e4e4e4;
			overflow: hidden;
			border-radius: 0;
			box-shadow: inset 0 -1px 0 #e4e4e4;
			padding: 15px 10px 15px 20px;
			background: #fff;
			margin: 0;
			font-size: 15px;
		}
	}
}

// Striped list
ul.striped-list{
	display: block;
	li{
		display: block;
		margin: 0 0 5px 0;
		padding: 10px 10px;
		background: #f3f3f3;
		font-size: 15px;
		&:nth-of-type(even){background: #e2e2e2}
	}
}







