@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300italic,400italic);

// -----------------------------------//
// ELEMENTS COLOURS
// -----------------------------------//
$slideOut-panel-bg: #f7f7f7;

$link-bg-colour: #fff;
$link-bg-colour-hover: #ebebeb;
$links-colour: #444444;

$slide-click-colour: #444444;


/* Full container */
#slideOut {
	position: absolute;
	width: auto;
	height: auto;
	top: 246px;
	left: -250px;
	background: $slideOut-panel-bg;
	box-shadow:0 0 0 1px  rgba(0, 0, 0, .08), 8px 8px 0 0 rgba(143,143,143,0.15);
	border-radius: 0 10px 10px 0;
	overflow: hidden;
	font-family: 'Lato', sans-serif;
	padding: 10px 0 10px 10px;
    z-index: 50;
}


/* Click to slide */
#slideClick {
	float: right;
	height: 100%;
	width: 70px;
	cursor: pointer;
	position: relative;
	cursor: pointer;
	
	span{
		width: 160px;
		height: 70px;
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: 25px;
		color: $slide-click-colour;
		white-space: nowrap;
		transform: rotate(-90deg);
		margin-left: -73px;
		margin-top: -37px;
		&:before{
			content: "";
			width: 7px;
			height: 12px;
			background: url(/i/design/arrow-open-close.png) no-repeat center center;
			position: absolute;
			left: 48%;
			top: 57%;
			transform: rotate(90deg);
		}
	}
}

/* Content links */
#slideContent {
	width: auto;
	height: auto;
	padding: 0;
	margin: 0;
	float: left;
	color: #EFEFEF;
	text-align: center;
	border-radius: 0 0 0 8px;
}


/* Box with links  */
ul.quick-links{
	width: 238px;
	display: block;
	padding: 1px;
	text-align: center;
	display: inline-block;
	box-shadow:inset 0 0 0 1px #e4e4e4;
	overflow: hidden;
	border-radius: 8px;
	
	li{
		background: none;
		margin: 0;
		padding: 0;
		display: block;
		
		/* Border radius top */
		&:first-of-type{
			a{border-radius: 7px 7px 0 0;}
		}
		
		/* Border radius bottom */
		&:last-of-type{
			a{border-radius: 0 0 7px 7px;box-shadow: none;}
		}
		
		a{
			background: $link-bg-colour url(/i/design/arrow-quick-links.png) no-repeat 24px 26px;
			color: $links-colour;
			font-size: 21px;
			display: block;
			text-align: left;
			box-shadow:inset 0 -1px 0  #e4e4e4;
			padding: 16px 10px 18px 58px;
			&:hover{
				background-color: $link-bg-colour-hover;
				text-decoration: none;
			}
		}
	}
}

/* Change the arrow  */
.open span::before{transform: rotate(-90deg) !important;}
.flip { display: none; }

@media only screen and (max-width: 750px) {
	#slideOut{
		position: relative;
		top: 0;
		left: 0;
		padding: 5px 3% 0 2%;
		overflow: visible;
		height: auto !important;
		box-shadow: 0 0 0 1px rgba(0,0,0,0.08);
		border-radius: 0 0 10px 10px;
		margin: 0 5%;
	}

	#slideClick{
		display: none;
		span{
			transform: rotate(0);
			position: absolute;
			top: 0;
			left: 0;
			width: auto;
			height: auto;
			margin-left: 0;
			margin-top: 0;
			position: relative;
		}
	}
	
	#slideContent{
		float: none;
	}
	
	ul.quick-links{
		width: 100%;
		li{
			a{
				padding: 6px 0 6px 20%;
				font-size: 1.1em;
				background-position: 8% 10px;
			}
		}
	}

	
	.flip{
		z-index: 800;
		margin: 0;
		padding:0 0 5px 0;
		cursor: pointer;
		font-size: 16px;
		color: $slide-click-colour;
		display: block;
		
		img{display: block; margin: 0 auto; transform: rotate(90deg);}
	}

	.open img{transform: rotate(-90deg);}
}